import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 10-10-10 to a 10RM`}</p>
    <p>{`then,`}</p>
    <p>{`6:00 AMRAP of:`}</p>
    <p>{`15/10 Calorie Assault Bike`}</p>
    <p>{`5 Deadlifts (275/185)`}</p>
    <p>{`rest 1:00, then:`}</p>
    <p>{`5:00 AMRAP of:`}</p>
    <p>{`10 T2B`}</p>
    <p>{`10 KB SDHP (53/35)`}</p>
    <p>{`rest 1:00, then:`}</p>
    <p>{`4:00 AMRAP of:`}</p>
    <p>{`15 Pushups`}</p>
    <p>{`10 Ring Rows`}</p>
    <p>{`For total reps.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Wednesday, July 4th, we will have 1 class at 9:00am that is free
for everyone so bring a friend!  Open gym will follow from 10am-1pm but
the 9:00am class is the only class for the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      